import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    
    <div className="logo">
      <span className="icon fa-pencil"></span>
    </div>
    <h1>NO SE ENCONTR&oacute;</h1>
    <p>Selecionaste una ruta que no existe... que tristesa.</p>
  
  </Layout>
)

export default NotFoundPage
